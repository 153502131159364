<script>
import ModelForm from '@/components/Form/ModelForm.vue';
import MailLogDataService from "@/services/MailLogDataService";

export default {
  name: 'MailLogForm',
  extends: ModelForm,
  props: {
    apiSlug: { default: 'mail_logs' },
    vueSlug: { default: 'mail_log' }
  },
  data() {
    return {
      dataService: MailLogDataService,
      modelConfig: MailLogDataService.getEditorConfig(),
      currentEntity: MailLogDataService.getEntityDefinition(),
    }
  },
  setup() {
    return ModelForm.setup();
  },
  mounted() {
    // disable buttons
    this.disableFormButtons();
  }
}
</script>
